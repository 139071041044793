import './App.css'
import { useState, useEffect, createContext, useContext } from 'react'
import { supabase } from './supabaseClient'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Layout from './components/layout'
import Login from './components/auth/login'

// Create a context for authentication
const AuthContext = createContext();

const theme = createTheme({
  palette: {
    primary: { main: '#4CAF50' },
    secondary: { main: '#dc004e' },
  },
  typography: {
    fontFamily: '"IBM Plex Mono", monospace',
    // Optionally customize specific typography variants
    h1: {
      fontFamily: '"IBM Plex Mono", monospace',
    },
    h2: {
      fontFamily: '"IBM Plex Mono", monospace',
    },
    h3: {
      fontFamily: '"IBM Plex Mono", monospace',
    },
    h4: {
      fontFamily: '"IBM Plex Mono", monospace',
    },
    h5: {
      fontFamily: '"IBM Plex Mono", monospace',
    },
    h6: {
      fontFamily: '"IBM Plex Mono", monospace',
    },
    body1: {
      fontFamily: '"IBM Plex Mono", monospace',
    },
    body2: {
      fontFamily: '"IBM Plex Mono", monospace',
    },
    button: {
      fontFamily: '"IBM Plex Mono", monospace',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: '"IBM Plex Mono", monospace',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#fff', // Set default text color to white
        },
        outlined: {
          color: '#4CAF50', // Text color for secondary buttons
        },
      },
    },
  },
});

// Custom hook to use the Auth context
export const useAuth = () => useContext(AuthContext);

export default function App() {
  const [session, setSession] = useState(null); // Initialize with no session
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initializeSession = async () => {
      // Fetch the current session using the Supabase method
      const {
        data: { session },
      } = await supabase.auth.getSession();
      setSession(session);
      setLoading(false); // Set loading to false after fetching session
    };

    initializeSession();

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  // No loading screen if session is already known
  if (loading) {
    return (
      <div className="splash-screen">
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <AuthContext.Provider value={{ session }}>
        {session ? <Layout session={session} /> : <Login />}
      </AuthContext.Provider>
    </ThemeProvider>
  );
}
