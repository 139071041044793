import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import { supabase } from '../../supabaseClient'

const Login = () => {
    // Build the redirect URL dynamically based on the current domain
    const redirectTo = `${window.env?.REACT_APP_FRONTEND_URL || process.env.REACT_APP_FRONTEND_URL}`
    console.log(redirectTo)

    return (
        <div className="login-wrapper">
            <div className="login-left">
                <img src="/images/logo-black.png" alt="Kevlog Logo" />
                <Auth 
                    supabaseClient={supabase} 
                    appearance={{ theme: ThemeSupa }} 
                    providers={['google']}
                    redirectTo={redirectTo}
                />
            </div>
            <div className="login-right">
                <img src="/images/login-bg.jpg" alt="Kevlog Sign-in Image"/>
            </div>
        </div>
    )
}

export default Login