import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Card, CardActionArea, CardContent, Typography, Box } from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import AssessmentIcon from '@mui/icons-material/Assessment'; // Icon for Scoring Heatmaps
import DescriptionIcon from '@mui/icons-material/Description'; // Dummy icon

const Datasets = () => {
    const navigate = useNavigate();

    const handleFunnelsClick = () => {
        navigate('/datasets/funnels');
    };

    const cardStyle = {
        width: '120px',
        height: '120px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'transform 0.3s',
    };

    const inactiveCardStyle = {
        ...cardStyle,
        backgroundColor: '#f5f5f5',
    };

    const inactiveStyle = {
        color: 'grey',
    };

    return (
        <Box sx={{ paddingTop: 0, paddingLeft: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'left', height: '64px', marginBottom: 2 }}>
                <Typography variant="h4" component="h1">
                    Datasets
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', minHeight: 'calc(100vh - 64px)' }}>
                <Grid container spacing={3} justifyContent="center">
                    <Grid item>
                        <Card
                            style={cardStyle}
                            onMouseEnter={(e) => {
                                e.currentTarget.style.transform = 'scale(1.05)';
                            }}
                            onMouseLeave={(e) => {
                                e.currentTarget.style.transform = 'scale(1)';
                            }}
                        >
                            <CardActionArea onClick={handleFunnelsClick}>
                                <CardContent>
                                    <BarChartIcon
                                        sx={{
                                            fontSize: 30,
                                            transition: 'color 0.3s',
                                            '&:hover': {
                                                color: 'green',
                                            },
                                        }}
                                    />
                                    <Typography variant="body2" component="div">
                                        Funnels Data
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item>
                        <Card
                            style={cardStyle}
                            onMouseEnter={(e) => {
                                e.currentTarget.style.transform = 'scale(1.05)';
                            }}
                            onMouseLeave={(e) => {
                                e.currentTarget.style.transform = 'scale(1)';
                            }}
                        >
                            <CardActionArea>
                                <CardContent>
                                    <AssessmentIcon
                                        sx={{
                                            fontSize: 30,
                                            transition: 'color 0.3s',
                                            '&:hover': {
                                                color: 'green',
                                            },
                                        }}
                                    />
                                    <Typography variant="body2" component="div">
                                        Scoring Heatmaps
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    {[...Array(14)].map((_, index) => (
                        <Grid item key={index}>
                            <Card style={inactiveCardStyle}>
                                <CardContent style={inactiveStyle}>
                                    <DescriptionIcon style={{ fontSize: 30 }} />
                                    <Typography variant="body2" component="div">
                                        More Datasets...
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
};

export default Datasets;
