import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  CircularProgress,
  Alert,
  Snackbar
} from '@mui/material';
import { format } from 'date-fns';
import { supabase } from '../../../supabaseClient';

const DataExtraction = ({ funnelId, API_URL }) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(5);
  const [totalCount, setTotalCount] = useState(0);
  const [updating, setUpdating] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const fetchFiles = async () => {
    setLoading(true);
    try {
      // Get total count
      const { count } = await supabase
        .from('funnel_hubspot_files')
        .select('*', { count: 'exact', head: true })
        .eq('funnel_id', funnelId);

      // Get paginated data
      const { data, error } = await supabase
        .from('funnel_hubspot_files')
        .select('*')
        .eq('funnel_id', funnelId)
        .order('created_at', { ascending: false })
        .range(page * rowsPerPage, (page + 1) * rowsPerPage - 1);

      if (error) throw error;

      setFiles(data || []);
      setTotalCount(count || 0);
      
      // Set last updated from the most recent file
      if (data && data.length > 0) {
        setLastUpdated(data[0].created_at);
      }
    } catch (error) {
      console.error('Error fetching files:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (funnelId) {
      fetchFiles();
    }
  }, [funnelId, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleUpdateData = async () => {
    setUpdating(true);
    setError(null);
    setSuccess(false);

    try {
      // Get the session for access token and tenant ID
      const { data: { session }, error: sessionError } = await supabase.auth.getSession();
      
      if (sessionError) throw new Error('Failed to get session');
      if (!session) throw new Error('No active session');

      const tenantId = session?.user?.app_metadata?.tenant_id;
      
      // Call backend to update data
      const response = await fetch(`${API_URL}/api/hubspot/extract-data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          access_token: session.access_token,
          tenant_id: tenantId,
          funnel_id: funnelId
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to update data');
      }

      const data = await response.json();
      setSuccess(true);
      
      // Refresh the files list
      await fetchFiles();
    } catch (error) {
      console.error('Error updating data:', error);
      setError(error.message);
    } finally {
      setUpdating(false);
    }
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2, paddingTop: 2 }}>
        <Button
          variant="contained"
          onClick={handleUpdateData}
          disabled={updating}
          startIcon={updating && <CircularProgress size={20} />}
        >
          {updating ? 'Updating...' : 'Update Data'}
        </Button>
        <Typography variant="body2" color="text.secondary">
          Last updated: {lastUpdated ? format(new Date(lastUpdated), 'dd/MM/yyyy HH:mm:ss') : 'Never'}
        </Typography>
      </Box>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>File Path</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={2} align="center">
                  <CircularProgress size={24} />
                </TableCell>
              </TableRow>
            ) : files.length === 0 ? (
              <TableRow>
                <TableCell colSpan={2} align="center">
                  No files found
                </TableCell>
              </TableRow>
            ) : (
              files.map((file) => (
                <TableRow key={file.id}>
                  <TableCell>
                    {format(new Date(file.created_at), 'dd/MM/yyyy HH:mm:ss')}
                  </TableCell>
                  <TableCell>{file.file_path}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={totalCount}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5]}
        />
      </TableContainer>
    </Box>
  );
};

export default DataExtraction; 