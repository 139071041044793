import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { Box, Typography, IconButton, Avatar, Stack, Container } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import BarChartIcon from '@mui/icons-material/BarChart';
import StorageIcon from '@mui/icons-material/Storage';
import { Link } from 'react-router-dom';
import Reports from './reports';
import Datasets from './datasets';
import WorkspaceSettings from './workspace/workspace_settings';
import CohortMQLS from './cohort/mqls';
import Funnels from './funnels/funnels';
import FunnelForm from './funnels/funnel-form';
import Conversions from './conversions/conversions';

const Layout = ({ session }) => {
    const [tenantName, setTenantName] = useState('');
    const [loading, setLoading] = useState(true);
    const [isCollapsed, setIsCollapsed] = useState(false);

    useEffect(() => {
        const fetchTenantName = async () => {
            if (session) {
                const tenantId = session.user.app_metadata.tenant_id;

                const { data, error } = await supabase
                    .from('tenants')
                    .select('name')
                    .eq('id', tenantId)
                    .single();

                if (error) {
                    console.error('Error fetching tenant name:', error);
                    setTenantName('Error fetching tenant name');
                } else {
                    setTenantName(data.name);
                }
            }
            setLoading(false);
        };

        fetchTenantName();
    }, [session]);

    if (loading) {
        return <Box>Loading...</Box>;
    }

    return (
        <Router>
            <Box 
                display="flex" 
                id="wrapper-layout"
            >
                {/* Sidebar */}
                <Box 
                    className={`border-right ${isCollapsed ? 'collapsed' : ''}`} 
                    id="sidebar-wrapper"
                    sx={{
                        borderRight: '1px solid #dee2e6',
                        height: '100vh',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    {/* Logo and Toggle Button */}
                    <Box 
                        sx={{ 
                            p: 2,
                            borderBottom: '1px solid #dee2e6'
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            {!isCollapsed && (
                                <Box
                                    component="img"
                                    src="/images/logo-black.png"
                                    alt="KEvLog Logo"
                                    sx={{ width: '100px' }}
                                />
                            )}
                            <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                                <MenuIcon />
                            </IconButton>
                        </Box>
                    </Box>

                    {/* Menu Items */}
                    <Stack sx={{ flex: 1 }}>
                        <Link to="/reports" className="list-group-item list-group-item-action">
                            <BarChartIcon />
                            <Typography 
                                component="span" 
                                className="menu-text"
                                sx={{ ml: 1 }}
                            >
                                Reports
                            </Typography>
                        </Link>
                        <Link to="/datasets" className="list-group-item list-group-item-action">
                            <StorageIcon />
                            <Typography 
                                component="span" 
                                className="menu-text"
                                sx={{ ml: 1 }}
                            >
                                Datasets
                            </Typography>
                        </Link>
                        <Link to="/workspace/" className="list-group-item list-group-item-action">
                            <SettingsIcon />
                            <Typography 
                                component="span" 
                                className="menu-text"
                                sx={{ ml: 1 }}
                            >
                                Workspace
                            </Typography>
                        </Link>
                    </Stack>

                    {/* Footer */}
                    <Box sx={{
                        p: 2,
                        borderTop: '1px solid #dee2e6',
                    }}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1.5,
                            }}
                        >
                            <Avatar
                                sx={{
                                    width: 40,
                                    height: 40,
                                    bgcolor: '#4CAF50',
                                    flexShrink: 0
                                }}
                            >
                                {session?.user?.email?.charAt(0).toUpperCase()}
                            </Avatar>

                            {!isCollapsed && (
                                <>
                                    <Box sx={{ 
                                        flex: 1, 
                                        minWidth: 0
                                    }}>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                fontWeight: 500,
                                                color: '#212121',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}
                                        >
                                            {session?.user?.email || 'Guest'}
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                color: '#666',
                                                display: 'block',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}
                                        >
                                            {tenantName}
                                        </Typography>
                                    </Box>

                                    <IconButton
                                        onClick={() => supabase.auth.signOut()}
                                        size="small"
                                        sx={{
                                            color: '#666',
                                            flexShrink: 0,
                                            '&:hover': {
                                                color: '#4CAF50',
                                                bgcolor: 'rgba(76, 175, 80, 0.04)'
                                            }
                                        }}
                                    >
                                        <LogoutIcon fontSize="small" />
                                    </IconButton>
                                </>
                            )}
                        </Box>
                    </Box>
                </Box>

                {/* Page Content */}
                <Box 
                    flex={1}
                    className={isCollapsed ? 'collapsed' : ''}
                    id="page-content-wrapper"
                >
                    <Container>
                        <Routes>
                            <Route path="/" element={<Typography variant="h1">Welcome to KEvLog tools</Typography>} />
                            <Route path="/reports" element={<Reports />} />
                            <Route path="/reports/lifecycle-cohort" element={<CohortMQLS />} />
                            <Route path="/reports/conversions" element={<Conversions />} />
                            <Route path="/datasets" element={<Datasets />} />
                            <Route path="/datasets/funnels" element={<Funnels />} />
                            <Route path="/workspace/" element={<WorkspaceSettings workspaceId={session?.user?.user_metadata?.tenant_id || session?.user?.app_metadata?.tenant_id} />} />
                            <Route path="/funnels/new" element={<FunnelForm />} />
                            <Route path="/funnels/:id/edit" element={<FunnelForm />} />
                            <Route path="*" element={<Navigate to="/" replace />} />
                        </Routes>
                    </Container>
                </Box>
            </Box>
        </Router>
    );
}

export default Layout;