import React, { useState, useEffect, useRef } from 'react';
import { 
    Box, 
    Typography, 
    TextField, 
    Button, 
    Paper,
    Alert,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    CircularProgress,
    Modal,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material';
import { supabase } from '../../supabaseClient';
import { useLocation } from 'react-router-dom';

const WorkspaceSettings = ({ workspaceId }) => {
    const API_URL = window.env?.REACT_APP_API_URL || process.env.REACT_APP_API_URL;
    const ENV_HUBSPOT_CLIENT_ID = window.env?.REACT_APP_HUBSPOT_CLIENT_ID || process.env.REACT_APP_HUBSPOT_CLIENT_ID;
    const ENV_HUBSPOT_REGION = window.env?.REACT_APP_HUBSPOT_REGION || process.env.REACT_APP_HUBSPOT_REGION;
    const [workspace, setWorkspace] = useState(null);
    const [workspaceName, setWorkspaceName] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const processedCode = useRef(null);
    const location = useLocation();
    const [workspaceUsers, setWorkspaceUsers] = useState([]);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [openInviteModal, setOpenInviteModal] = useState(false);
    const [inviteEmail, setInviteEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [inviting, setInviting] = useState(false);

    // HubSpot OAuth configuration
    const HUBSPOT_CLIENT_ID = ENV_HUBSPOT_CLIENT_ID;
    const REDIRECT_URI = `${window.location.origin}/workspace/`;
    const HUBSPOT_REGION = ENV_HUBSPOT_REGION;
    const SCOPES = [
        'crm.schemas.deals.read',
        'oauth',
        'crm.objects.companies.read',
        'crm.lists.read',
        'crm.objects.deals.read',
        'crm.schemas.contacts.read',
        'crm.objects.contacts.read',
        'crm.schemas.companies.read'
    ].join(' ');

    useEffect(() => {
        fetchWorkspaceData();
        fetchWorkspaceUsers();
        const handleOAuthCallback = async () => {
            const params = new URLSearchParams(location.search);
            const code = params.get('code');
            
            if (code && !isProcessing && processedCode.current !== code) {
                setIsProcessing(true);
                processedCode.current = code;

                try {
                    const hubspotData = await exchangeCodeForToken(code);
                    await updateHubSpotIntegration(hubspotData);
                    
                    // Clear the code from the URL
                    window.history.replaceState({}, document.title, window.location.pathname);
                    
                    setSuccess('HubSpot connected successfully');
                } catch (error) {
                    console.error('Error handling HubSpot callback:', error);
                    if (!error.message.includes('Code already used')) {
                        setError('Failed to connect HubSpot: ' + error.message);
                    }
                } finally {
                    setIsProcessing(false);
                    setTimeout(() => {
                        setSuccess(null);
                        setError(null);
                    }, 3000);
                }
            }
        };

        handleOAuthCallback();

        return () => {
            setIsProcessing(false);
        };
    }, [location.search]);

    // Handle the OAuth callback from HubSpot
    const handleHubSpotCallback = async () => {
        const params = new URLSearchParams(location.search);
        const code = params.get('code');
        
        if (code) {
            try {
                const hubspotData = await exchangeCodeForToken(code);
                
                await updateHubSpotIntegration(hubspotData);
                
                // Clear the code from the URL
                window.history.replaceState({}, document.title, window.location.pathname);
                
                setSuccess('HubSpot connected successfully');
                setTimeout(() => setSuccess(null), 3000);
            } catch (error) {
                console.error('Error handling HubSpot callback:', error);
                setError('Failed to connect HubSpot: ' + error.message);
                setTimeout(() => setError(null), 3000);
            }
        }
    };

    // Initiate HubSpot OAuth flow
    const connectHubSpot = () => {
        const authUrl = `https://app-${HUBSPOT_REGION}.hubspot.com/oauth/authorize?` +
            `client_id=${HUBSPOT_CLIENT_ID}&` +
            `redirect_uri=${encodeURIComponent(REDIRECT_URI)}&` +
            `scope=${encodeURIComponent(SCOPES)}`;
        
        window.location.href = authUrl;
    };

    // Update workspace with HubSpot integration data
    const updateHubSpotIntegration = async (hubspotData) => {
        try {
            const { data: workspaceData, error: fetchError } = await supabase
                .from('tenants')
                .select('*')
                .eq('id', workspaceId)
                .single();

            if (fetchError) throw fetchError;

            const currentMeta = workspaceData.meta || {};
            const expiresOn = new Date(Date.now() + (hubspotData.expires_in * 1000)).toISOString();
            
            const updatedMeta = {
                ...currentMeta,
                integrations: [
                    ...(currentMeta.integrations || []).filter(
                        integration => !integration.HubSpot
                    ),
                    {
                        HubSpot: {
                            access_token: hubspotData.access_token,
                            refresh_token: hubspotData.refresh_token,
                            expires_on: expiresOn,
                            token_type: hubspotData.token_type
                        }
                    }
                ]
            };

            const { error: updateError } = await supabase
                .from('tenants')
                .update({ meta: updatedMeta })
                .eq('id', workspaceId);

            if (updateError) throw updateError;
            
            setWorkspace({ ...workspaceData, meta: updatedMeta });
            
        } catch (error) {
            console.error('Error updating HubSpot integration:', error);
            throw error;
        }
    };

    // Fetch workspace data
    const fetchWorkspaceData = async () => {
        try {
            const { data, error } = await supabase
                .from('tenants')
                .select('*')
                .eq('id', workspaceId)
                .single();

            if (error) throw error;

            setWorkspace(data);
            setWorkspaceName(data.name || '');
        } catch (error) {
            console.error('Error fetching workspace:', error);
            setError('Failed to fetch workspace data');
        }
    };

    // Handle workspace name update
    const handleUpdateName = async () => {
        try {
            const { error } = await supabase
                .from('tenants')
                .update({ 
                    name: workspaceName,
                    // Only update the name field, nothing else
                })
                .eq('id', workspaceId)
                .select(); // Add select() to verify the update

            if (error) {
                console.error('Update error:', error);
                throw error;
            }

            setIsEditing(false);
            setSuccess('Workspace name updated successfully');
            setTimeout(() => setSuccess(null), 3000);
        } catch (error) {
            console.error('Error updating workspace name:', error);
            setError('Failed to update workspace name: ' + error.message);
            setTimeout(() => setError(null), 3000);
        }
    };

    // Add this function to your component
    const exchangeCodeForToken = async (code) => {
        try {
            const response = await fetch(`${API_URL}/api/hubspot/oauth/callback`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ code })
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Failed to exchange code for token');
            }

            return data;
        } catch (error) {
            console.error('Error exchanging code for token:', error);
            throw error;
        }
    };

    const refreshHubSpotToken = async (refreshToken) => {
        try {
            const response = await fetch(`${API_URL}/api/hubspot/refresh-token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ refresh_token: refreshToken })
            });

            if (!response.ok) {
                throw new Error('Failed to refresh token');
            }

            return await response.json();
        } catch (error) {
            console.error('Error refreshing token:', error);
            throw error;
        }
    };

    const getValidHubSpotToken = async () => {
        try {
            const { data: tenant, error } = await supabase
                .from('tenants')
                .select('meta')
                .eq('id', workspaceId)
                .single();

            if (error) throw error;

            const hubspotIntegration = tenant.meta?.integrations?.find(i => i.HubSpot);
            if (!hubspotIntegration) {
                throw new Error('No HubSpot integration found');
            }

            const { HubSpot: hubspot } = hubspotIntegration;
            const expiresOn = new Date(hubspot.expires_on);
            const now = new Date();
            
            // If token expires in less than 10 seconds, refresh it
            if ((expiresOn - now) / 1000 < 10) {
                const newTokenData = await refreshHubSpotToken(hubspot.refresh_token);
                await updateHubSpotIntegration(newTokenData);
                return newTokenData.access_token;
            }

            return hubspot.access_token;
        } catch (error) {
            console.error('Error getting valid token:', error);
            throw error;
        }
    };

    // Updated function to fetch workspace users from backend API
    const fetchWorkspaceUsers = async () => {
        setLoadingUsers(true);
        try {
            const { data: { session } } = await supabase.auth.getSession();
            if (!session) throw new Error('No active session');

            const response = await fetch(`${API_URL}/api/workspace/users`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session.access_token}`
                },
                body: JSON.stringify({
                    workspace_id: workspaceId
                })
            });

            if (!response.ok) {
                throw new Error('Failed to fetch workspace users');
            }

            const data = await response.json();
            setWorkspaceUsers(data);
        } catch (error) {
            console.error('Error fetching workspace users:', error);
            setError('Failed to fetch workspace users');
        } finally {
            setLoadingUsers(false);
        }
    };

    useEffect(() => {
        if (workspaceId) {
            fetchWorkspaceUsers();
        }
    }, [workspaceId]);

    // Email validation function
    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    // Handle invite modal
    const handleOpenInviteModal = () => setOpenInviteModal(true);
    const handleCloseInviteModal = () => {
        setOpenInviteModal(false);
        setInviteEmail('');
        setEmailError('');
    };

    // Handle email input change
    const handleEmailChange = (e) => {
        const email = e.target.value;
        setInviteEmail(email);
        if (email && !validateEmail(email)) {
            setEmailError('Please enter a valid email address');
        } else {
            setEmailError('');
        }
    };

    // Handle invite submission
    const handleInviteUser = async () => {
        if (!validateEmail(inviteEmail)) {
            setEmailError('Please enter a valid email address');
            return;
        }

        setInviting(true);
        try {
            const { data: { session } } = await supabase.auth.getSession();
            if (!session) throw new Error('No active session');

            const response = await fetch(`${API_URL}/api/workspace/invite`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session.access_token}`
                },
                body: JSON.stringify({
                    workspace_id: workspaceId,
                    email: inviteEmail
                })
            });

            if (!response.ok) {
                throw new Error('Failed to invite user');
            }

            setSuccess('Invitation sent successfully');
            handleCloseInviteModal();
            // Refresh users list
            fetchWorkspaceUsers();
        } catch (error) {
            console.error('Error inviting user:', error);
            setError('Failed to invite user: ' + error.message);
        } finally {
            setInviting(false);
        }
    };

    return (
        <Box sx={{ p: 3 }}>
            <Paper sx={{ p: 3 }}>
                <Typography variant="h5" gutterBottom>
                    Workspace Settings
                </Typography>

                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}

                {success && (
                    <Alert severity="success" sx={{ mb: 2 }}>
                        {success}
                    </Alert>
                )}

                {/* Workspace Name Section */}
                <Box sx={{ mb: 4 }}>
                    <Typography variant="h6" gutterBottom>
                        Workspace Name
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        {isEditing ? (
                            <>
                                <TextField
                                    value={workspaceName}
                                    onChange={(e) => setWorkspaceName(e.target.value)}
                                    size="small"
                                    fullWidth
                                />
                                <Button 
                                    variant="contained" 
                                    onClick={handleUpdateName}
                                    size="small"
                                >
                                    Save
                                </Button>
                                <Button 
                                    onClick={() => setIsEditing(false)}
                                    size="small"
                                >
                                    Cancel
                                </Button>
                            </>
                        ) : (
                            <>
                                <Typography>{workspaceName}</Typography>
                                <Button 
                                    variant="outlined" 
                                    onClick={() => setIsEditing(true)}
                                    size="small"
                                >
                                    Edit
                                </Button>
                            </>
                        )}
                    </Box>
                </Box>

                {/* HubSpot Integration Section */}
                <Box>
                    <Typography variant="h6" gutterBottom>
                        HubSpot Integration
                    </Typography>
                    {workspace?.meta?.integrations?.find(i => i.HubSpot) ? (
                        <Box sx={{ mb: 2 }}>
                            <Typography variant="body1">
                                Connected to HubSpot
                            </Typography>
                            <Button 
                                variant="outlined" 
                                onClick={connectHubSpot}
                                sx={{ mt: 1 }}
                            >
                                Reconnect HubSpot
                            </Button>
                        </Box>
                    ) : (
                        <Button 
                            variant="contained" 
                            onClick={connectHubSpot}
                        >
                            Connect HubSpot
                        </Button>
                    )}
                </Box>
                
            </Paper>
            <Paper sx={{ p: 3, paddingTop: 2, marginTop: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6">Users</Typography>
                    <Button 
                        variant="contained" 
                        onClick={handleOpenInviteModal}
                        size="small"
                        disabled={true}
                    >
                        Invite New User
                    </Button>
                </Box>
                
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="workspace users table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loadingUsers ? (
                                <TableRow>
                                    <TableCell colSpan={2} align="center">
                                        <CircularProgress size={24} />
                                    </TableCell>
                                </TableRow>
                            ) : workspaceUsers.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={2} align="center">
                                        No users found
                                    </TableCell>
                                </TableRow>
                            ) : (
                                workspaceUsers.map((user) => (
                                    <TableRow key={user.id}>
                                        <TableCell component="th" scope="row">
                                            {user.name}
                                        </TableCell>
                                        <TableCell>{user.email}</TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            {/* Invite User Modal */}
            <Dialog 
                open={openInviteModal} 
                onClose={handleCloseInviteModal}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Invite New User</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Email Address"
                        type="email"
                        fullWidth
                        value={inviteEmail}
                        onChange={handleEmailChange}
                        error={!!emailError}
                        helperText={emailError}
                        disabled={inviting}
                    />
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={handleCloseInviteModal} 
                        disabled={inviting}
                    >
                        Cancel
                    </Button>
                    <Button 
                        onClick={handleInviteUser} 
                        variant="contained"
                        disabled={!inviteEmail || !!emailError || inviting}
                    >
                        {inviting ? <CircularProgress size={24} /> : 'Invite'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default WorkspaceSettings;
